var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c("c-table", {
            ref: "table",
            attrs: {
              title: "개선 목록",
              tableId: "impr01",
              columns: _vm.gridImpr.columns,
              data: _vm.hazard.imprs,
              merge: _vm.gridImpr.merge,
              gridHeight: _vm.gridHeight,
            },
            on: { linkClick: _vm.linkClick },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-table",
            {
              ref: "gridImpr",
              attrs: {
                title: "항목외 개선 목록",
                columns: _vm.gridImpr2.columns,
                data: _vm.gridImpr2.data,
                gridHeight: _vm.gridHeight2,
                usePaging: false,
                filtering: false,
                columnSetting: false,
              },
              on: { linkClick: _vm.linkClick2 },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable &&
                      !_vm.disabled2 &&
                      _vm.popupParam.heaMuscleSopId
                        ? _c("c-btn", {
                            attrs: {
                              label: "개선요청",
                              showLoading: false,
                              icon: "add",
                            },
                            on: { btnClicked: _vm.addImpr },
                          })
                        : _vm._e(),
                      _vm.editable &&
                      !_vm.disabled2 &&
                      _vm.popupParam.heaMuscleSopId
                        ? _c("c-btn", {
                            attrs: {
                              label: "즉시조치",
                              showLoading: false,
                              icon: "add",
                            },
                            on: { btnClicked: _vm.addiimImpr },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }